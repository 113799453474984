// codigo personalizado Fco Arroyave


//import logo from './logo.svg';
//import './App.css';

// Importa el archivo de estilos de Bootstrap
import './bootstrap.css';

// Importa la función useState de React para manejar el estado
import { useState } from "react";

function App() {
  // Define el estado 'value' para almacenar el valor actual del input
  const [value, setValue] = useState('');
  // Define el estado 'list' para almacenar los elementos agregados a la lista
  const [list, setList] = useState([]);
  // Define el estado 'completed' para rastrear el estado de cada tarea (completada o no)
  const [completed, setCompleted] = useState([]);

  // Función para manejar el cambio de texto en el input
  const handleOnChange = (e) => {
    setValue(e.target.value);
  };


 // Función para agregar el valor actual a la lista de tareas y marcarlo como incompleto en 'completed'
  const handleOnClick = () => {
    // Verifica que 'value' no esté vacío antes de agregar la tarea
    if (value.trim() !== '') { 
      setList([...list, value]);
      setCompleted([...completed, false]); // Agrega estado "incompleto" para la nueva tarea
      setValue('');
    } else {
      alert("La tarea no puede estar vacía."); // Muestra un mensaje de alerta si el valor está vacío
    }
  };


  // Función para eliminar un elemento de la lista en un índice específico
  const handleOnDelete = (index) => {
    const newList = list.filter((_, i) => i !== index);
    const newCompleted = completed.filter((_, i) => i !== index);
    setList(newList);
    setCompleted(newCompleted);
  };

  // Función para marcar una tarea como completada o incompleta
  const handleOnComplete = (index) => {
    const newCompleted = [...completed];
    newCompleted[index] = !newCompleted[index]; // Alterna entre completado y no completado
    setCompleted(newCompleted);
  };

  // Calcula el progreso de las tareas completadas en porcentaje
  const completedCount = completed.filter(status => status).length;
  const progress = (completedCount / list.length) * 100 || 0; // Evita NaN cuando la lista está vacía

  // Genera el color de la barra de progreso como un degradado entre rojo (0%) y verde (100%)
  const getProgressColor = (progress) => {
    // Colores en formato RGB: rojo (255, 0, 0) y verde (0, 128, 0)
    const red = Math.round(255 - (255 * (progress / 100))); // Disminuye de rojo a 0
    const green = Math.round(128 * (progress / 100)); // Incrementa de 0 a verde
    return `rgb(${red}, ${green}, 0)`; // Color en función del progreso
  };

  return (
    <div className="container mt-4">
      {/* Título de la aplicación */}
      <h1 className="text-primary mb-4">Todo List</h1>

      {/* Grupo de entrada: campo de texto y botón */}
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          value={value}
          placeholder="Escribe tu texto"
          onChange={handleOnChange} // Maneja los cambios en el campo de texto
        />
        <button className="btn btn-primary" onClick={handleOnClick}>
          Agregar Tarea
        </button>
      </div>

      {/* Barra de progreso para indicar el porcentaje de tareas completadas */}
      <div className="progress mb-3">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progress}%`, backgroundColor: getProgressColor(progress) }}
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {Math.round(progress)}%
        </div>
      </div>

      {/* Lista de tareas */}
      <ul className="list-group">
        {list.length > 0 ? (
          list.map((item, i) => (
            <li key={i} className="list-group-item d-flex justify-content-between align-items-center">
              {item}
              <div>
                {/* Botón de completar tarea */}
                <button
                  className={`btn btn-sm ${completed[i] ? "btn-success" : "btn-outline-secondary"} me-2`}
                  onClick={() => handleOnComplete(i)}
                >
                  {completed[i] ? "Completado" : "Completar"}
                </button>
                {/* Botón de borrar tarea */}
                <button className="btn btn-danger btn-sm" onClick={() => handleOnDelete(i)}>
                  Borrar Item
                </button>
              </div>
            </li>
          ))
        ) : (
          <p className="text-muted">No Hay Tareas</p>
        )}
      </ul>
    </div>
  );
}

export default App;
